
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Course {
  trimester: string;
  subject: string;
  title: string;
  module: string;
  desc: string;
  resources: File[];
  classrooms: string[];
  status: string;
  students: string[];
}

interface File {
  name: string;
  url: string;
  storedName: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface ClassRoom {
  _id: string;
  name: string;
  subjects: Subject[];
}

interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  schoolarYearsHistory: string[];
}

export default defineComponent({
  name: "edit-course",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const loadingSktelton = ref(true);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const classRooms = ref<ClassRoom[]>([]);
    const studentList = ref<any[]>([]);
    const uploadPercentage = ref(0);
    const spec = ref<string>("0");

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const classID = route.params.id;

    const courseID = route.params.id;

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const course = ref<Course>({
      trimester,
      subject: "",
      title: "",
      module: "",
      desc: "",
      resources: [],
      classrooms: [],
      status: "validated",
      students: [],
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
      classrooms: Yup.array()
        .min(1, t("course.requiredClassroom"))
        .required(t("course.requiredClassroom")),
      students: Yup.array().test(
        "required-students",
        t("course.requiredStudents"),
        (value) => {
          if (spec.value == "0") return true;
          if (spec.value == "1" && (value?.length == 0 || value == undefined))
            return false;
          else return true;
        }
      ),
    });

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileUrl = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const fetchClassRoomDetails = async (teacherId) => {
      await ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: teacherId,
        },
      })
        .then(({ data }) => {
          let subjectList: Subject[] = [];
          for (const classroom of data) {
            subjectList.push(...classroom.subjects);
            const classRoomSubjects: Subject[] = classroom.subjects;

            classroom.modulesSpecific.map((m) => {
              if (!modules.value.find((t) => t._id == m._id))
                modules.value.push(m);
              classRoomSubjects.push(
                ...m.subjects.filter(
                  (s) => !classRoomSubjects.find((ss) => ss._id == s._id)
                )
              );
            });

            classroom.modules.map((m) => {
              if (!modules.value.find((t) => t._id == m._id))
                modules.value.push(m);
              classRoomSubjects.push(
                ...m.subjects.filter(
                  (s) => !classRoomSubjects.find((ss) => ss._id == s._id)
                )
              );
            });

            //remove duplicated
            subjectList = subjectList.filter(
              (subject, index) =>
                subjectList.findIndex((s) => s._id == subject._id) === index &&
                !modules.value.find((m) =>
                  m.subjects.find((ss) => ss._id == subject._id)
                ) &&
                subject.status !== "inactive"
            );

            classRooms.value.push({
              _id: classroom.classRoom._id,
              name: classroom.classRoom.name,
              subjects: classRoomSubjects,
            });
          }
          subjects.value = subjectList;
        })
        .catch((e) => console.log(e));
    };
    ApiService.get(`/lms/course/${courseID}`)
      .then(async ({ data }) => {
        course.value = {
          subject: data.subject._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          resources: [],
          trimester: data.trimester ? data.trimester : "0",
          classrooms: data.classrooms,
          status: data.status,
          students: data.students,
        };
        data.resources.forEach((file) => {
          course.value.resources.push({
            storedName: file,
            name: getFileName(file),
            url: getFileUrl(file),
          });
        });
        if (data.status == "validated") router.push("/404");
        await fetchClassRoomDetails(data.teacher._id);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        loadingSktelton.value = false;
      });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      event("Edit course", {
        event_category: "Course",
        event_label: "Course section",
        value: 1,
      });

      const data = new FormData();

      for (const name in course.value)
        if (!["classrooms", "status", "resources"].includes(name))
          data.append(name, course.value[name] ? course.value[name] : "--");

      if (course.value.classrooms.length != 0) {
        course.value.classrooms.forEach((cr: string) => {
          data.append("classrooms", cr);
        });
      }

      if (uploadFiles.value.length > 0) {
        uploadFiles.value.forEach((file) => {
          if (file.raw) data.append("resources", file.raw, file.raw.name);
          else data.append("resources[]", file.storedName);
        });
      } else {
        course.value.resources.map((file) => {
          data.append("resources[]", file.storedName);
        });
      }

      if (!submitButtonRef.value) {
        return;
      }

      data.delete("students");
      if (course.value.students.length != 0) {
        course.value.students.forEach((st: string) => {
          data.append("students", st);
        });
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.patch(
        `/lms/course/${courseID}`,
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.editedCourseInfo"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("course.okay"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            if (store.getters.hasAdvisorAccess)
              router.push(`/coursesValidation/${courseID}`);
            else router.push(`/course/${courseID}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const avaliableClassRooms = computed(() =>
      classRooms.value.filter((c: ClassRoom) =>
        c.subjects.find((s: Subject) => s._id == course.value.subject)
      )
    );

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const getStudents = (classrooms: string[]) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data;
        })
        .catch((error) => console.log(error));
    };

    const clearStudents = () => {
      course.value.students = [];
    };

    const avaliableStudents = computed(() => studentList.value);

    return {
      t,
      courseID,
      subjects,
      modules,
      upload,
      course,
      validationSchema,
      submit,
      submitButtonRef,
      avaliableClassRooms,
      updateUploadFiles,
      handleExceed,
      uploadPercentage,
      customColorMethod,
      store,
      studentList,
      spec,
      clearStudents,
      avaliableStudents,
      loadingSktelton,
    };
  },
});
